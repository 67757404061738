angular.module('llax')
    .controller('ItemsController',
    function(
        $rootScope, $scope, $routeParams, $route, $timeout, $modal, $controller, $location, $log, $translate, $window,
        $q, Auth, ChannelService, CommunicationChannelResource, ContactsResource, WatchlistService,
        ItemChangesQueueManager, ItemResource, PublicationMode, ReactBridge,
        SelectionResource, ValidateItemService, growl, $filter, TaskResource, QueryItemResource) {

        $controller('FetchItemController', {$scope: $scope});

        var LocalStorage = $window.localStorage;

        var EDIT_ITEM_URL = "/edit/";
        var NEW_ITEM_URL = "/edit";
        var COPY_ITEM_URL = "/copy/";
        var ITEM_BROWSE_URL = "/browse";
        var FOCUS_SECTION_URL = "/section";
        var FOCUS_ATTRIBUTE_URL = "/attribute";

        var GRID_STATES_KEY = "grid-states";
        var DEFAULT_GRID_STATE_KEY = "default-state";

        $scope.currentLayout = 'browse';

        $scope.items = [];

        $scope.loadingItems = false;
        $scope.columnDefs = $scope.columnDefs || [];

        var bulkSearch = LocalStorage.getItem("BulkSearch");
        $rootScope.bulkSearch =  !_.isEmpty(bulkSearch) ? angular.fromJson(bulkSearch) : false;

        ChannelService.register(ChannelService.ITEM_CHANGED_EVENT);

        $scope.hasItemPermission = function(action, item) {
            return Auth.hasItemPermission(action, item);
        };

        $scope.hasCategoryPermission = function(action, category) {
            return Auth.hasAnyPermission(Auth.OBJECT_TYPE_ITEMS, action, {category__: category});
        };

        $scope.hasExportPermission = function() {
            return $scope.hasAnyReadPermission() && Auth.hasAnyPermission(Auth.OBJECT_TYPE_UI, 'view.exports');
        };

        $scope.hasTasksPermission = function() {
            return Auth.hasAnyPermission(Auth.OBJECT_TYPE_UI, 'view.tasks');
        };

        $scope.hasMediaAssetExportPermission = function() {
            var selectedItems = $scope.getSelectedFullItems();
            var hasPermission = true;
           _.forEach(selectedItems, function(item, index, array) {
               var context = { namingPattern: Auth.ANY, item: item };
               if (!Auth.hasPermission('mediaAssetExport', 'show', context)) {
                   hasPermission = false;
               }
           });
           return hasPermission;
        };

        $scope.checkItemPermission = function(action, item) {
            if (!$scope.hasItemPermission(action, item)) {
                var primaryKey = item.primaryKey__;
                var category = item.category__;
                var variables = {
                    action: action
                };
                var message;
                if (!_.isEmpty(primaryKey)) {
                    message = "ITEM_ACTION.FORBIDDEN_FOR_PRIMARY_KEY";
                    variables.primaryKey = primaryKey;
                } else if (!_.isEmpty(category)) {
                    message = "ITEM_ACTION.FORBIDDEN_FOR_CATEGORY";
                    variables.category = category;
                } else {
                    message = "ITEM_ACTION.FORBIDDEN";
                }
                growl.warning(message, {variables: variables});
                return false;
            }
            return true;
        };

        $scope.checkItemCategoryPermission = function(action, category) {
            var item = {
                category__: category,
            };
            item[Auth.CONTEXT_DEFAULT_VALUE] = Auth.ANY;
            return $scope.checkItemPermission(action, item);
        };

        $scope.hasAnyActionPermission = function(action) {
            var category;
            if (_.isNil($routeParams.category) || $routeParams.category === 'NO_CATEGORY') {
                category = null;
            } else {
                category = $routeParams.category;
            }
            return $scope.hasCategoryPermission(action, category);
        };

        $scope.hasAnyCreatePermission = function() {
            return $scope.hasAnyActionPermission('create');
        };

        $scope.hasAnyDeletePermission = function() {
            return $scope.hasAnyActionPermission('delete');
        };

        $scope.hasAnyEditPermission = function() {
            return $scope.hasAnyActionPermission('edit');
        };

        $scope.hasAnyPublishPermission = function() {
            return $scope.hasAnyActionPermission('publish');
        };

        $scope.hasAnyReadPermission = function() {
            return $scope.hasAnyActionPermission('read');
        };

        $scope.$on('channelMessageReceived', function(event, eventData) {

            if (eventData.event === ChannelService.ITEM_CHANGED_EVENT) {

                var userId = eventData.data.userId;
                var typeOfChange = eventData.data.typeOfChange;
                if (typeOfChange === 'error' && userId == $rootScope.user.userId) {
                    growl.error(eventData.data.message, { variables: eventData.data });
                    return;
                }

                var primaryKeys = eventData.data.primaryKeys;
                var categories = eventData.data.categories;

                if (typeOfChange === 'deleted') {
                    WatchlistService.removeWatchlistItems(primaryKeys);
                }

                // Check if selected category is in any of the "updated" categories
                var hasToUpdate;
                if (_.isEmpty(categories) || (_.isNil($routeParams.selectionId) && _.isNil($routeParams.category))) {
                    hasToUpdate = true;
                } else if (!_.isNil($routeParams.selectionId)) {
                    var watchlistCategories = WatchlistService.getWatchlistCategories();
                    hasToUpdate = !_.isEmpty(watchlistCategories) && _.some(categories, function(category) {
                       return _.includes(watchlistCategories, category);
                    });
                } else if ($routeParams.category === "NO_CATEGORY" && _.includes(categories, null)) {
                    hasToUpdate = true;
                } else if (_.includes(categories, $routeParams.category)) {
                    hasToUpdate = true;
                }

                if (hasToUpdate) {

                    // Find and delete the items in the list
                    if (typeOfChange === 'deleted') {
                        _.remove($scope.items, function(item) {
                            return _.includes(primaryKeys, item.primaryKey__);
                        });
                    } else {

                        // Reload only if the updated items exists in the browse list.
                        var updatedItemsinBrowseList = _.find($scope.items, function (item) {
                            return _.includes(primaryKeys, item.primaryKey__);
                        });

                        var browseListUpdated = false;
                        if (!_.isEmpty(updatedItemsinBrowseList)) {
                            browseListUpdated = true;
                        }

                        // Reload also if browse list is empty means on the first time items are getting uploaded.
                        if (_.isEmpty($scope.items) || browseListUpdated) {
                            reLoadSelectedItems(eventData).then(function (updateAll) {
                                if (!updateAll && (userId != $rootScope.user.userId)) {
                                    // Only notify other users
                                    growl.warning('ITEM.NOTIFICATION.ITEM_UPDATED', {
                                        referenceId: 1,
                                        ttl: -1
                                    });
                                }
                            });
                        }
                    }

                }

            } else if (eventData.event === ChannelService.MASS_UPDATE_SUMMARY_CHANGED_EVENT) {
                var status = eventData.data.status;
                if (eventData.data.startedBy === $rootScope.user.userId) {
                    if (status === 'RUNNING') {
                        var totalCount = eventData.data.totalCount;
                        var user = eventData.data.startedBy;
                        growl.info('MASS_UPDATE_EVENT.STARTED', {
                            variables: {count: totalCount, user: user},
                            referenceId: 1,
                            ttl: -1
                        });
                    } else if (status === 'FINISHED') {
                        var finishedCount = eventData.data.finishedCount;
                        growl.info('MASS_UPDATE_EVENT.FINISHED', {
                            variables: {count: finishedCount},
                            referenceId: 1,
                            ttl: -1
                        });
                    }
                }
            } else if (eventData.event === ChannelService.VALIDATION_FINISHED_EVENT) {
                var validationResults = eventData.data.validationResults;
                for (var primaryKey in validationResults) {
                    var originalItem = _.find($scope.items, {primaryKey__: primaryKey});
                    if (!_.isNil(originalItem)) {
                        originalItem.validation_dirty__ = false;
                        originalItem.validation__ = validationResults[primaryKey].validationErrors;
                        originalItem.validation_warnings__ = validationResults[primaryKey].validationWarnings;
                        originalItem.attributeStates__ = validationResults[primaryKey].attributeStates;
                    }
                }
            }
            if (eventData.event === ChannelService.PUBLICATION_TASK_CHANGED_EVENT) {
                if (eventData.data.typeOfChange === "updated") {

                    var publication = eventData.data.publication;
                    var publishedItems = publication.publishedItems;
                    if (!_.isEmpty(publishedItems)) {

                        var destinationId = _.toString(publication.destinationId);
                        var destinations = [ destinationId ];
                        var subDestination = publication.subDestination;
                        if (!_.isNil(subDestination)) {
                            destinations.push(destinationId + ":" + subDestination);
                        }

                        _.forEach(publishedItems, function(primaryKey) {

                            var publishedItem = _.find($scope.items, { primaryKey__: primaryKey });
                            if (!_.isNil(publishedItem)) {

                                // FIXME: The backend only includes items on successful publication,
                                // which is BEFORE the task is actually set to 'SUCCESS'
                                // Thus, we are now directly setting the status to 'SUCCESS'
                                publishedItem.publicationStatus__ = 'SUCCESS';
                                publishedItem.publicationStartedAt__ = publication.creationDate;

                                // The published state is dependent on the type of the publication,
                                // i.e. if 'ADD', the state is true always;
                                // if 'DELETE', we need to update the 'publications__' of the items
                                // and set it to false, if empty
                                var publications = publishedItem.publications__ || [];
                                if (publication.publicationType === 'ADD') {
                                    publications = _.union(publications, destinations);
                                } else {
                                    publications = _.difference(publications, destinations);
                                }
                                publishedItem.publications__ = publications;

                                if (_.isEmpty(publications)) {
                                    publishedItem.publishedAt__ = null;
                                    publishedItem.published__ = false;
                                } else {
                                    publishedItem.publishedAt__ = publication.updatedDate;
                                    publishedItem.published__ = true;
                                }

                            }
                        });

                    }

                }
            }
        });
        function reLoadSelectedItems(eventData) {
            var deferred = $q.defer();
            var primaryKeys = _.get(eventData,'data.primaryKeys');
            if (_.isEmpty(primaryKeys) || primaryKeys.length >= 50) {
                primaryKeys = _.map($scope.items, 'primaryKey__');
            }
            var selectedItems = {
                primaryKeys: primaryKeys
            };
            SelectionResource.save(selectedItems,
                function(response) {
                    QueryItemResource.createCursorAwareResource().query({selectionId:response.selectionId}, function(res, headers) {
                        var updateAll = true;
                        var fields = $rootScope.dataModel.filteredLayoutAttributeNames($scope.currentLayout);
                        fields = fields.concat($rootScope.dataModel.ADDITIONAL_FIELDS || []);
                        fields = fields.concat($rootScope.dataModel.filterPrimaryKeyPartAttributes());
                        _.forEach(res,function(resItem) {
                            var item = _.find($scope.items, { 'primaryKey__' : resItem.primaryKey__});
                            if (!_.isEmpty(item)) {
                                _.forEach(fields, function(field) {
                                    item[field] = resItem[field];
                                });
                            } else {
                                updateAll = false;
                            }
                        });
                        deferred.resolve(updateAll);
                    });
                }
            );
            return deferred.promise;
        }

        $scope.hasSelectedItems = function() {
            return $scope.allItemsSelected || WatchlistService.hasWatchlistItems();
        };

        $scope.$on('itemSearchQueryChanged', function(event, query) {
            $scope.itemSearchQueryChanged = true;
            if (_.isEmpty(query)) {
                return;
            } else {
                $scope.query = query;
                createItemTableColumnDefinition();
                if (!hasAnySortState()) {
                    $scope.sortOrder = {};
                }
                $scope.fetchItems();
            }
        });

        $scope.$on('selectedItemsUpdated', function(event, items) {
            if (items === 'ALL') {
                $scope.gridApi.selection.selectAllVisibleRows();
                $scope.allItemsSelected = true;
            } else if (items === 'NONE') {
                $scope.gridApi.selection.clearSelectedRows();
                $scope.allItemsSelected = false;
                WatchlistService.clearWatchlist();
                if ($routeParams.selectionId) {
                    $location.search('selectionId', null);
                }
            }
        });

        $scope.$on('dataModelLoaded', function() {
            initDependingOnDatamodel();
        });

        function initDependingOnDatamodel() {
            createItemTableColumnDefinition();
            $scope.fetchItems();
            startGridCellEditListeners();
        }

        $scope.fetchItems = function(config) {
            // Stop queues and reset validations
            if ($scope.rowEditItem) {
                stopItemChangesQueues();
                $scope.rowEditValidations = null;
            }

            var fields = $rootScope.dataModel.filteredLayoutAttributeNames($scope.currentLayout);
            $scope.doFetchItems(config, fields);
        };

        $scope.hasRestrictions = function() {
            for (var key in $scope.restrictions) {
                if (!_.isUndefined($scope.restrictions[key])) {
                    return true;
                }
            }
            return false;
        };

        $scope.isRowEditable = function(row) {
            if (_.isUndefined(row.editable)) {
                row.editable = $scope.hasItemPermission('edit', row.entity);
            }
            return row.editable;
        };

        $scope.isRowReadable = function(row) {
            if (_.isUndefined(row.readable)) {
                row.readable = $scope.isRowEditable(row) || $scope.isRowReadonly(row);
            }
            return row.readable;
        };

        $scope.isRowReadonly = function(row) {
            if (_.isUndefined(row.readonly)) {
                row.readonly = $scope.hasItemPermission('read', row.entity) && !$scope.isRowEditable(row);
            }
            return row.readonly;
        };

        function itemCellEditableCondition(scope) {

            if (!scope.row.editable) {
                return false;
            }

            var item = scope.row.entity;
            var colName = scope.col.colDef.name;

            if (!_.isEmpty(item.attributeStates__) && item.attributeStates__.contains((colName + ":readonly"))) {
                return false;
            }

            if (!_.isEmpty(item.readonlyAttributes__) && item.readonlyAttributes__.contains((colName))) {
                return false;
            }

            // We disallow editing cells (double click events) if the attribute is hidden, but actually
            // hiding it, is on the renderer and cell templates.
            if (!_.isEmpty(item.attributeStates__) && item.attributeStates__.contains((colName + ":hidden"))) {
                return false;
            }

            return true;
        }

        function createItemTableColumnDefinition() {

            var columnDefs = [];

            if ($rootScope.dataModel.hasLayout($scope.currentLayout)) {

                var categories = [];
                if (!_.isEmpty($routeParams.category) && $routeParams.category !== "NO_CATEGORY") {
                    categories.push($routeParams.category);
                } else if (!_.isNil($routeParams.selectionId)) {
                    categories = WatchlistService.getWatchlistCategories();
                }

                var layoutAttributes = [];
                if (_.isEmpty(categories)) {
                    layoutAttributes = $rootScope.dataModel.filteredLayoutAttributes($scope.currentLayout);
                } else {
                    var browseAttributes = $rootScope.dataModel.filteredLayoutAttributes($scope.currentLayout);
                    var categoryAttributes = [];
                    _.forEach(categories, function(category) {
                        categoryAttributes = categoryAttributes.concat($rootScope.dataModel.categoryAttributes(category));
                    });
                    layoutAttributes = _.intersection(browseAttributes, categoryAttributes);
                    if (_.isEmpty(layoutAttributes)) {
                        layoutAttributes = $rootScope.dataModel.filteredLayoutAttributes($scope.currentLayout);
                    }
                }

                var length = layoutAttributes.length;
                var attr = null;
                var sectionName = $rootScope.dataModel.sectionAttributes($scope.currentLayout)[0].name;

                for (var i = 0; i < length; i++) {
                    attr = layoutAttributes[i];
                    $rootScope.setSectionAttributeParam($scope.currentLayout, null, attr, 'iconSize', '1em', $scope.item);
                    $rootScope.setSectionAttributeParam($scope.currentLayout, null, attr, 'valuesFormat', ['label', '-', 'key'], $scope.item);
                    var sortable = attr.typeName !== "Dimensional";
                    var col = {
                        attribute: attr,
                        field: attr.name,
                        name: attr.name,
                        headerTooltip: true,
                        width: '*',
                        minWidth: 120,
                        enableCellEdit: !$rootScope.dataModel.sectionAttributeParam(
                                $scope.currentLayout,
                                undefined,  // item
                                sectionName,
                                attr.name,
                                'readonly'),
                        enableCellSelection: false,
                        enableSorting: sortable,
                        cellEditableCondition: itemCellEditableCondition
                    };

                    // based on the attribute type different kinds of cell editors are set on the column
                    $rootScope.generateCellEditors(attr, col, $scope, {context: $scope.currentLayout});
                    columnDefs[i] = col;
                }

                columnDefs.push(
                    {
                        displayName: $translate.instant('ACTIONS'),
                        headerTooltip: true,
                        name: 'actions',
                        enableCellEdit: false,
                        enableCellSelection: false,
                        allowCellFocus: false,
                        cellClass: 'text-right',
                        headerCellClass: 'items-action-header',
                        enableColumnResizing: true,
                        enableSorting: false,
                        enableHiding: false,
                        pinnedRight: true,
                        minWidth: 250,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-ng-class="{\'bg-color-light-green\': row.entity.audited__}">' +
                            '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions" style="display: flex; justify-content: flex-end; align-items: center;">' +
                                '<span id="publication_{{row.entity.primaryKey__}}" data-ng-if="row.entity.publishedAt__" class="empty-slot review-icon" title="Published" data-ng-mouseenter="grid.appScope.showPublishValidationsTooltip(row.entity)"' +
                                'data-ng-mouseleave="grid.appScope.hideTooltip(row.entity)">' +
                                    '<i class="syncons syncons-checkmark"></i> ' +
                                    '<span data-ng-if="grid.appScope.checkPublicationsDirty(row.entity)" class="notification red" style="margin-top: 7px;">!</span>' +
                                '</span>' +
                                '<button id="item_review_{{row.entity.primaryKey__}}" type="button" class="btn btn-cell action-review" tabindex="-1"' +
                                    'data-ng-if="row.entity.reviewCountTotal__"' +
                                    'data-ng-disabled="row.entity.reviewCountTotal__ == 0"' +
                                    'data-ng-click="grid.appScope.showItemReview(row.entity)"' +
                                    'data-ng-mouseenter="grid.appScope.showItemReviewTooltip(row.entity)"' +
                                    'data-ng-mouseleave="grid.appScope.hideTooltip(row.entity)">' +
                                    '<span class="review-icon">' +
                                        '<i data-ng-class="grid.appScope.getReviewClass(row.entity)"></i>' +
                                        '<span class="notification red" data-ng-show="row.entity.reviewErrors__.length > 0">{{row.entity.reviewErrors__.length}}</span>' +
                                        '<span class="notification lower orange" data-ng-show="row.entity.reviewWarnings__.length > 0">{{row.entity.reviewWarnings__.length}}</span>' +
                                    '</span>' +
                                '</button>' +
                                '<span data-ng-switch on="grid.appScope.validationStatus(row.entity)" class="validation-status">' +
                                    '<span data-ng-switch-when="dirty" class="icon validation-icon" title="Validations">' +
                                        '<i class="glyphicon glyphicon-refresh spin"></i>' +
                                    '</span>' +
                                    '<span id="item_validations_{{row.entity.primaryKey__}}"' +
                                        'data-ng-switch-when="non-compliant"' +
                                        'class="icon validation-icon"' +
                                        'data-ng-mouseenter="grid.appScope.showItemValidationsTooltip(row.entity)"' +
                                        'data-ng-mouseleave="grid.appScope.hideTooltip(row.entity)">' +
                                        '<i class="syncons syncons-error"></i>' +
                                        '<span id="{{row.entity.primaryKey__}}" class="notification red" data-ng-show="row.entity.validation__.length > 0">{{row.entity.validation__.length}}</span>' +
                                        '<span id="{{row.entity.primaryKey__}}_warnings" class="notification lower orange" data-ng-show="row.entity.validation_warnings__.length > 0">{{row.entity.validation_warnings__.length}}</span>' +
                                    '</span>' +
                                '</span>' +
                                '<button type="button" data-ng-disabled="row.readable === false || (row.readable === undefined && !grid.appScope.isRowReadable(row))" id="view-row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}" class="btn btn-cell action-edit action-btn-slot" tabindex="-1" title="{{::\'VIEW_ITEM\' | translate}}" data-ng-click="grid.appScope.showEditor(row, undefined, { onShowItemDetails: true })"> ' +
                                    '<i class="syncons syncons-catalog" data-ng-if="row.editable === true || (row.editable === undefined && !grid.appScope.isRowEditable(row))"></i> ' +
                                    '<i class="syncons syncons-view" data-ng-if="row.readonly === true || (row.readonly === undefined && !grid.appScope.isRowReadonly(row))"></i> ' +
                                '</button>' +
                                '<button type="button" data-ng-disabled="row.readable === false || (row.readable === undefined && !grid.appScope.isRowReadable(row))" id="edit-row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}" class="btn btn-cell action-edit action-btn-slot" tabindex="-1" data-ng-click="grid.appScope.showEditor(row)"> ' +
                                    '<i class="syncons syncons-edit" data-ng-if="row.editable === true || (row.editable === undefined && !grid.appScope.isRowEditable(row))" title="{{::\'EDIT_ITEM\' | translate}}"></i> ' +
                                    '<i class="syncons syncons-item-detail" data-ng-if="row.readonly === true || (row.readonly === undefined && !grid.appScope.isRowReadonly(row))" title="{{::\'ITEM.DETAIL_PAGE\' | translate}}"></i> ' +
                                '</button>' +
                                '<button type="button" has-rights="delete.items" data-ng-disabled="!grid.appScope.hasItemPermission(\'delete\', row.entity)" class="btn btn-cell action-delete action-btn-slot" tabindex="-1" title="{{::\'DELETE_ITEMS\' | translate}}" data-ng-click="grid.appScope.deleteItem(row.entity)"> ' +
                                    '<i class="syncons syncons-delete"></i> ' +
                                '</button>' +
                            '</div>' +
                        '</div>'
                    }
                );

                if ($scope.gridApi) {
                    $rootScope.restoreGridState($scope, $scope.gridApi, GRID_STATES_KEY, getGridStateKey(), columnDefs);
                }

                $scope.gridOptions = {
                    data: 'items',
                    virtualizationThreshold: 200,
                    columnDefs: columnDefs,
                    enableCellEdit: true,
                    enableColumnMenus: false,
                    enableColumnMoving : true,
                    enableColumnResize: true,
                    enableGridMenu: true,
                    infiniteScrollDown: true,
                    rowHeight: 34,
                    onRegisterApi: function(gridApi) {

                        $scope.gridApi = gridApi;
                        gridApi.infiniteScroll.on.needLoadMoreData($scope, $scope.loadMore);

                        gridApi.core.on.sortChanged($scope, $scope.sortChanged);
                        gridApi.selection.clearSelectedRows();
                        gridApi.selection.on.rowSelectionChanged($scope, function(row, event) {
                            if (row.isSelected) {
                                WatchlistService.addWatchlistItem(row.entity);
                            } else {
                                WatchlistService.removeWatchlistItem(row.entity);
                            }
                        });
                        gridApi.selection.on.rowSelectionChangedBatch($scope, function(rows, event) {
                            _.forEach(rows, function(row) {
                                if (row.isSelected) {
                                    WatchlistService.addWatchlistItem(row.entity);
                                } else {
                                    WatchlistService.removeWatchlistItem(row.entity);
                                }
                            });
                        });

                        // Initialize the grid state with 'dynamic' gridStateKey and columnDefs,
                        // otherwise the state would be updated for the wrong category
                        // and the 'Reset' menu would reset the wrong columns
                        $rootScope.initGridState($scope, $scope.gridApi, GRID_STATES_KEY, getGridStateKey, function() {
                            return columnDefs;
                        });

                        $timeout(function() {
                            $scope.gridApi.core.handleWindowResize();
                        });
                        gridApi.core.on.rowsRendered($scope, function () {
                            $rootScope.$broadcast('reloadBrowseListFileTypes');
                        });

                    }
                };
            }
        }

        function getGridStateKey() {

            if (!_.isNil($routeParams.selectionId) || $routeParams.category === 'NO_CATEGORY') {
                return null;
            }

            var gridStateKey;
            if (!_.isEmpty($routeParams.category)) {
                gridStateKey = $routeParams.category + '-state';
            } else {
                gridStateKey = DEFAULT_GRID_STATE_KEY;
            }

            return gridStateKey;
        }

        function hasAnySortState() {
            var hasAny = false;
            var gridStates = angular.fromJson(LocalStorage.getItem(GRID_STATES_KEY)) || {};
            var gridStateKey = getGridStateKey();
            var state = gridStates[gridStateKey];
            if (state && state.columns) {
                hasAny = !_.every(state.columns, function(col) {
                    return _.isEmpty(col.sort);
                });
            }
            return hasAny;
        }

        $scope.getReviewClass = function(item) {

            if (!item.reviewCountTotal__) {
                return "reviews reviews-none syncons syncons-decline";
            }

            // Only reviews with "RECEIVED" status
            if (item.reviewCountTotal__ === item.reviewCountReceived__) {
                return "reviews reviews-grey syncons syncons-comment";
            }

            // At least one REVIEWED and one APPROVED
            if (item.reviewCountReviewed__ > 0 && item.reviewCountApproved__ > 0) {
                return "reviews reviews-orange syncons syncons-channels";
            }

            // At least one REVIEWED
            if (item.reviewCountReviewed__ > 0) {
                return "reviews reviews-red syncons syncons-comment";
            }

            // At lease one APPROVED
            if (item.reviewCountApproved__ > 0) {
                return "reviews reviews-green syncons syncons-comment";
            }

            // Only REJECTED
            return "reviews reviews-yellow syncons syncons-comment";
        };

        var sortItemsObserver;
        var sortItemsObservable = rxjs.Observable.create(function(observer) {
            sortItemsObserver = observer;
        });

        sortItemsObservable.pipe(rxjs.debounceTime(500)).subscribe(function(order) {
            $scope.sortOrder = order;
            console.log("sortOrder", $scope.sortOrder);
            if (!_.isEmpty($scope.sortOrder.sort)) {
                $scope.fetchItems();
            }
        });

        $scope.sortChanged = function(grid, columns) {
            var cols = _.sortBy(columns, 'sort.priority');

            var sorting = {
                sort: [],
                direction: []
            };

            for (var i = 0; i < cols.length; i++) {
                sorting.sort.push(cols[i].field);
                sorting.direction.push(cols[i].sort.direction);
            }
            sortItemsObserver.onNext(sorting);
        };

        $scope.loadMore = function() {
            if (!$scope.loadingItems && !$scope.searchFinished) {
                $scope.page++;
                console.log("loading page " + $scope.page);
                $scope.fetchItems({append: true});
            }
        };

        // Function to call when item changes are transmitted
        function processTransmitItem(itemChanges, item) {
            var broadcast = ChannelService.broadcast(ChannelService.ITEM_EDITED_EVENT, item.primaryKey__, itemChanges);
            return broadcast;
        }

        // Function to call when item changes are validated
        function processValidateItem(itemChanges, item, previousItem, originalScopeItem) {
            // Ensure validations map entry exists
            $scope.rowEditValidations = $scope.rowEditValidations || {};
            $scope.rowEditValidations[item.primaryKey__] = $scope.rowEditValidations[item.primaryKey__] || {};

            var attributeNames = _.keys(itemChanges);
            var validateItem = ValidateItemService.validateItem($scope,
                                                                originalScopeItem,
                                                                item,
                                                                previousItem,
                                                                null,
                                                                $scope.rowEditValidations[item.primaryKey__],
                                                                attributeNames);
            return validateItem.$promise;
        }

        // Function to call when item is saved
        function processSaveItem(itemChanges, item, previousItem, originalItem) {
            if (!$scope.hasItemPermission('edit', item)) {
                var deferred = $q.defer();
                deferred.resolve();
                return deferred.promise;
            }
            var saveItem = ItemResource.save({}, item,
                function(savedItem) {
                    stopItemChangesQueues();
                    angular.copy(savedItem, originalItem);
                    growl.success("SAVE_SUCCESS_MESSAGE");
                },
                function(response) {
                    stopItemChangesQueues();
                    $scope.status = response.status;
                    if (response.data && response.data.message) {
                        growl.error(response.data.message, { variables: response.data.parameters });
                    } else {
                        growl.error("SAVE_ERROR_MESSAGE");
                    }
                    $route.reload();
                });
            return saveItem.$promise;
        }

        // The 'store' queue has to skip saving the item, if a cell is currently being edited
        var rowEditingActive = false;
        function skipSaveItem() {
            return rowEditingActive;
        }

        // Create queues for transmitting, validating and saving changes
        var queueManager = new ItemChangesQueueManager();
        queueManager.createQueue('transmitItemQueue', 300, processTransmitItem);
        queueManager.createQueue('validateItemQueue', 600, processValidateItem);
        queueManager.createQueue('storeItemQueue', 2000, processSaveItem, skipSaveItem);

        function startItemChangesQueues() {
            queueManager.start($scope, $scope.rowEditItem, 'rowEditItem');
        }

        function stopItemChangesQueues() {
            queueManager.stop();
            $scope.rowEditItem = null;
        }

        function laxGridStartCellEdit(event, row, col) {

            // Restart queues, when different item is edited
            var item = row.entity;
            if (item.primaryKey__) {
                if (!$scope.rowEditItem || $scope.rowEditItem.primaryKey__ != item.primaryKey__) {

                    if ($scope.rowEditItem) {
                        stopItemChangesQueues();
                    }

                    $scope.rowEditItem = item;
                    startItemChangesQueues();
                }
                rowEditingActive = true;
            }
        }

        function laxGridStopCellEdit(event, row, col) {
            if ($scope.rowEditItem) {

                rowEditingActive = false;

                // Force processing of item changes in 'store' queue
                var storeItemQueue = queueManager.getQueue('storeItemQueue');
                storeItemQueue.processItemChanges();

            }
        }

        function startGridCellEditListeners() {
            $log.debug("startGridCellEditListeners");
            $scope.deregisterCellEditListeners = [];
            $scope.deregisterCellEditListeners.push($scope.$on('laxGridStartCellEdit', laxGridStartCellEdit));
            $scope.deregisterCellEditListeners.push($scope.$on('laxGridStopCellEdit', laxGridStopCellEdit));
        }

        function stopGridCellEditListeners() {
            $log.debug("stopGridCellEditListeners");
            while ($scope.deregisterCellEditListeners && $scope.deregisterCellEditListeners.length > 0) {
                ($scope.deregisterCellEditListeners.pop())();
            }
        }

        function getCellValidations(row, col) {
            if (!$scope.rowEditValidations) {
                return [];
            }

            var item = row.entity;
            var itemValidations = $scope.rowEditValidations[item.primaryKey__] || {};

            var attributeName = col.field;
            var cellValidations = itemValidations[attributeName] || [];

            return cellValidations;
        }

        // Define validation methods for grid like 'getCellValidationMessages' and 'getRowValidationMessages'.
        ValidateItemService.defineGridValidationMethods($scope, getCellValidations);

        $scope.validationStatus = function(item) {
            if (item.validation_dirty__ === true) {
                return "dirty";
            } else if (!_.isEmpty(item.validation__) || !_.isEmpty(item.validation_warnings__)) {
                return "non-compliant";
            } else {
                return "compliant";
            }
        };

        $scope.depublishItems = function() {
            $scope.publishItems({mode: PublicationMode.DEPUBLISH});
        };

        $scope.publishItems = function(config) {

            var mode = config && config.mode || PublicationMode.PUBLISH;
            var selectedItemEntities = $scope.gridApi.selection.getSelectedRows();

            if (_.isEmpty($scope.items) || _.isEmpty(selectedItemEntities)) {
                growl.warning("NO_ITEMS_SELECTED");
                return false;
            }

            if (mode === PublicationMode.PUBLISH) {

                var customPublicationService = $rootScope.getService("CustomPublicationSettings");
                var isAllowedInvalidItem = false;
                if (!_.isNil(customPublicationService) && !_.isNil(customPublicationService.allowInvalidItem)) {
                    isAllowedInvalidItem = customPublicationService.allowInvalidItem();
                }

                var item;
                for (var i = 0; i < selectedItemEntities.length; i++) {
                    item = selectedItemEntities[i];
                    if (isAllowedInvalidItem === false) {
                        if (item.validation_dirty__ || !_.isEmpty(item.validation__)) {
                            growl.warning("ITEM_INVALID_BEFORE_PUBLISHING");
                            return false;
                        }
                    }
                    if (!$scope.checkItemPermission('publish', item)) {
                        return false;
                    }
                }
            }

            $scope.selectedItems = _.map(selectedItemEntities, 'primaryKey__');
            if ($scope.allItemsSelected) {
                openPublicationsModal({itemsQuery: $scope.createItemRestrictions()}, mode);
            } else {
                openPublicationsModal({primaryKeys: $scope.selectedItems}, mode);
            }

        };

        function openPublicationsModal(itemSelection, mode) {
            if ($scope.selectedItems.length >= 1) {
                stopItemChangesQueues();
                stopGridCellEditListeners();

                var modalInstance = $modal.open({
                    templateUrl: 'tpl/publish-to-recipients-modal.tpl.html',
                    controller: 'PublishItemsController',
                    backdrop: true,
                    windowClass: 'publish-modal',
                    resolve: {
                        itemSelection: function() {
                            return itemSelection;
                        },
                        mode: function() {
                            return mode;
                        },
                        publicationData: function() {
                            return {};
                        }
                    }
                });
                modalInstance.result.finally(function() {
                    startGridCellEditListeners();
                });
            } else {
                growl.warning("NO_ITEMS_SELECTED");
            }
        }

        $scope.deleteItem = function(item) {
            if ($scope.checkItemPermission('delete', item)) {
                deleteItemsBySelection({primaryKeys: [item.primaryKey__]});
            }
        };

        $scope.deleteSelectedItems = function() {

            var selectedItemEntities = $scope.gridApi.selection.getSelectedRows();

            if (_.isEmpty($scope.items) || _.isEmpty(selectedItemEntities)) {
                growl.warning("NO_ITEMS_SELECTED");
                return false;
            }

            var item;
            for (var i = 0; i < selectedItemEntities.length; i++) {
                item = selectedItemEntities[i];
                if (!$scope.checkItemPermission('delete', item)) {
                    return false;
                }
            }

            var selection = $scope.createItemSelection();
            deleteItemsBySelection(selection);

        };

        function deleteItemsBySelection(selection) {
            if (selection.itemsQuery && selection.itemsQuery.selectionId) {
                stopItemChangesQueues();
                stopGridCellEditListeners();
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-item-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            var data = {
                                selectionId: selection.itemsQuery.selectionId
                            };
                            if (selection.primaryKeys) {
                                data.primaryKeys = selection.primaryKeys;
                            }
                            var itemsQuery = _.omit(selection.itemsQuery, _.isUndefined);
                            if (!_.isEmpty(itemsQuery)) {
                                data.itemsQuery = itemsQuery;
                            }
                            return data;
                        }
                    }
                });
                modalInstance.result.finally(function() {
                    if (selection.primaryKeys) {
                        $rootScope.$broadcast('updateOrganizationUsageLimit', -selection.primaryKeys.length);
                    }
                    startGridCellEditListeners();
                });
            } else {
                SelectionResource.save(selection, function(response) {
                    stopItemChangesQueues();
                    stopGridCellEditListeners();
                    var modalInstance = $modal.open({
                        templateUrl: 'tpl/confirm-delete-item-modal.tpl.html',
                        controller: 'ModalInstanceCtrl',
                        backdrop: true,
                        resolve: {
                            data: function() {
                                var data = {
                                    selectionId: response.selectionId
                                };
                                if (selection.primaryKeys) {
                                    data.primaryKeys = selection.primaryKeys;
                                }
                                var itemsQuery = _.omit(selection.itemsQuery, _.isUndefined);
                                if (!_.isEmpty(itemsQuery)) {
                                    data.itemsQuery = itemsQuery;
                                }
                                return data;
                            }
                        }
                    });
                    modalInstance.result.finally(function() {
                        if (selection.primaryKeys) {
                            $rootScope.$broadcast('updateOrganizationUsageLimit', -selection.primaryKeys.length);
                        }
                        startGridCellEditListeners();
                    });
                });
            }
        }

        function loadOrganizations() {
            return ContactsResource.query({},
                function(response) {
                    $scope.organizations = _.sortBy(_.filter(response, function(contact) {
                        return !_.isNil(contact.organizationId) &&
                            contact.state === 'ESTABLISHED' &&
                            (_.isNil(contact.contactRole) || contact.contactRole !== 'DATA_SUPPLIER');
                    }), function(contact) {
                        return _.toLower(contact.name);
                    });
                },
                function(response) {
                    $scope.status = response.status;
                }
            );
        }

        function loadCommunicationChannels() {
            return CommunicationChannelResource.query({},
                function(response) {
                    $scope.communicationChannels = response;
                },
                function(response) {
                    $scope.status = response.status;
                }
            );
        }

        var deregisterWatchEditorParamsItems;

        function setEditorParamsItems(params, items) {
            params.primaryKeys = _.map(items, 'primaryKey__');
        }

        $scope.showEditor = function(row, origPrimaryKey, editorState) {

            if (!_.isNil(editorState) && editorState.onShowItemDetails) {
                $rootScope.onShowItemDetails = editorState.onShowItemDetails;
            }

            // Stop queues and grid listeners
            stopItemChangesQueues();
            stopGridCellEditListeners();

            var primaryKey = (row && row.entity ? row.entity.primaryKey__ : null);
            if (row && primaryKey) {
                $location.displayUrl(EDIT_ITEM_URL + primaryKey);
            } else if (origPrimaryKey) {
                $location.displayUrl(COPY_ITEM_URL + origPrimaryKey);
            } else {
                $location.displayUrl(NEW_ITEM_URL);
            }

            var params = {
                category: $routeParams.category
            };
            if (origPrimaryKey) {
                params.origPrimaryKey = origPrimaryKey;
            } else if (primaryKey) {
                params.itemPrimaryKey = primaryKey;
            }
            setEditorParamsItems(params, $scope.items);
            if (!_.isEmpty($scope.task)) {
                params.task = $scope.task;
                params.forceCloseTask = true;
            }

            if ($scope.$watch) {
                if (deregisterWatchEditorParamsItems) {
                    deregisterWatchEditorParamsItems();
                }
                deregisterWatchEditorParamsItems = $scope.$watch('items', function(newValue, oldValue) {
                    setEditorParamsItems(params, newValue);
                });
            }

            if ($rootScope.hasSettingFeature('NEXT_EDITOR')) {

                var dialog = ReactBridge.mountDialog('ItemEditorDialog', '#next-item-editor', {
                    category: params.category,
                    editorState: editorState,
                    forceCloseTask: params.forceCloseTask,
                    originalPrimaryKey: params.origPrimaryKey,
                    primaryKey: params.itemPrimaryKey,
                    task: params.task,
                    onClose: function() {
                        $timeout(function(result) {

                            if (result) {
                                $scope.items.push(result);
                            }

                            $location.displayUrl(ITEM_BROWSE_URL);
                            $scope.rowEditValidations = null;
                            startGridCellEditListeners();
                        }, 0);
                        dialog.unmount();
                    },
                });

                return;
            }

            var editor = $modal.open({
                templateUrl: 'tpl/editor.tpl.html',
                controller: 'EditorController',
                backdrop: true,
                keyboard: false,
                windowClass: 'item-editor',
                resolve: {
                    params: function() {
                        return params;
                    }
                }
            });

            var reloadBrowseList = true;
            editor.result.then(function(result) {

                if (result.action === 'startMassUpdate' && !_.isNil(result.selection)) {
                    $scope.updateItems(result.selection);
                } else if (result.action === 'itemChanged' || result.action === 'itemUnchanged') {

                    // Update or add the edited item
                    // FIXME: We should instead find the grid's row by the item's primary key and update it
                    if (!_.isNil(result.item)) {
                        if (row) {
                            angular.copy(result.item, row.entity);
                        } else {
                            $scope.items.push(result.item);
                        }
                    }

                    // Start the editor with another item
                    if (!_.isNil(result.showItemPrimaryKey)) {

                        // Create a dummy row to start the editor with
                        // FIXME: If we were able to find the grid's row by the item's primary, we would not have to do this
                        row = {
                            entity: {
                                primaryKey__: result.showItemPrimaryKey
                            }
                        };

                        // No need to reload the browse list
                        reloadBrowseList = false;

                        // Start the editor after exiting the current function call to minimize the call-stack
                        $timeout(function() {
                            $scope.showEditor(row);
                        }, 1);

                    }

                }

            }).finally(function() {
                if (reloadBrowseList) {
                    $location.displayUrl(ITEM_BROWSE_URL);
                    $scope.rowEditValidations = null;
                    startGridCellEditListeners();
                }
            });

        };

        $scope.copyItem = function(item) {
            $scope.showEditor(null, item);
        };

        $scope.showEditorAddTask = function(items) {
            var taskObj = {
                taskListId: null
            };

            if (items == 'ALL' || (_.isArray(items) && items.length > 25)) {
                var selection = $scope.createItemSelection();
                SelectionResource.save(selection,
                    function(response) {
                        // $location.search('selectionId', response.selectionId);
                        taskObj.selectionId = response.selectionId;
                        openTaskEditor(taskObj);
                    }
                );
            } else {
                taskObj.items = items;
                openTaskEditor(taskObj);
            }
        };

        function openTaskEditor(taskObj) {
            stopItemChangesQueues();
            stopGridCellEditListeners();
            var editor = $modal.open({
                templateUrl: 'tpl/tasks-create-task.tpl.html',
                controller: 'CreateTaskController',
                backdrop: true,
                windowClass: 'publish-modal',
                resolve: {
                    data: function() {
                        return taskObj;
                    }
                }
            });
            editor.result.then(function(task) {
                console.log(task);
            })
            .finally(function() {
                startGridCellEditListeners();
            });
        }

        $scope.showMediaAssetExport = function(items) {
            var dialog = ReactBridge.mountDialog("ExportMediaAssetDialog", "#react-export-media-asset-node",
                {
                    items: items,
                    itemsSelection: $scope.createItemSelection(),
                    onClose: function(toWait) {
                        // Unmount the dialog after waiting if toWait is true, otherwise unmount immediately
                        function unmount(){
                            dialog.unmount();
                        }
                        return toWait? ReactBridge.unmountWhenReady(unmount): dialog.unmount();
                    }
                });
        };

        function prepareReviewAttributes() {
            // Set renderer and translations for review attributes
            $scope.reviewAttributes = $rootScope.dataModel.getReviewAttributes();
            $rootScope.prepareAttributes($scope.reviewAttributes);
        }
        prepareReviewAttributes();

        $scope.showItemReview = function(item) {
            stopItemChangesQueues();
            stopGridCellEditListeners();

            var modal = $modal.open({
                templateUrl: 'tpl/item-reviews-modal.tpl.html',
                controller: 'SupplierItemReviewController',
                backdrop: true,
                windowClass: 'publish-modal',
                scope: $scope,
                resolve: {
                    $attrs: function() {
                        return {
                            itemPrimaryKey : item.primaryKey__,
                            publicationDestination: $routeParams.publicationDestination,
                            reviewer: $routeParams.reviewer
                        };
                    }
                }
            });

            $scope.closeItemReviewModal = function () {
                modal.close();
            };

            modal.result.finally(function() {
                startGridCellEditListeners();
            });
        };

        $scope.compareItems = function(primaryKeys) {
            var selectedItems = {};
            var itemsRequests = [];
            _.forEach(primaryKeys, function(primaryKey) {
                var deferred = $q.defer();
                ItemResource.get({}, {
                        'primaryKey': primaryKey
                    },
                    function(itemResult) {
                        for (var key in itemResult) {
                            // try to parse JSON like Strings into JSON Objects
                            var val = itemResult[key];
                            if (val !== undefined && typeof val == "string" && (val.startsWith("{") || val.startsWith("["))) {
                                try {
                                    itemResult[key] = JSON.parse(val);
                                } catch (e) {}
                            }
                        }
                        deferred.resolve(itemResult);
                    });

                itemsRequests.push(deferred.promise);
            });
            $q.all(itemsRequests).then(function(items){
                selectedItems = items;
            $location.displayUrl("/compare/" + primaryKeys[0] + "/" + primaryKeys[1]);
            stopItemChangesQueues();
            stopGridCellEditListeners();
            var dialog = ReactBridge.mountDialog("CompareItemsDialog", "#react-compare-items-dialog",
            {
                items: selectedItems,
                rootScope: $rootScope,
                currentLayout: $scope.currentLayout,
                onClose: function() {
                    startGridCellEditListeners();
                    startItemChangesQueues();
                    $location.path(ITEM_BROWSE_URL);
                    dialog.unmount();
                }
            });
        });
        };

        $scope.filteredExportFormats = function() {
            return $filter('showExportFormatsFilter')($scope.exportFormats , $scope.getSelectedFullItems());
        };

        function loadTasks() {
            TaskResource.get({ taskId: $routeParams.taskId }, function(response) {
                $scope.task = response;
                $rootScope.$broadcast('taskLoaded', $scope.task);
            });
        }

        $scope.$on('$routeUpdate', function(scope, route) {
            if (_.isNil($routeParams.taskId)) {
                $scope.task = null;
            } else {
                loadTasks();
            }
        });

        $scope.newBulkSearch = function(queryValues, queryTooLong) {
            $scope.query= "";
            $rootScope.$broadcast('buckSearchPopupOpend');
            var modal = $modal.open({
                templateUrl: 'tpl/bulk-search.tpl.html',
                controller: 'bulkSearchController',
                backdrop: true,
                windowClass: 'bulk-search-modal',
                resolve: {
                    queryValues: function() {
                        return queryValues;
                    },
                    queryTooLong: function() {
                        return queryTooLong;
                    }
                }
            });
        };

        $scope.showLastBulkSearch = function() {
            delete $routeParams.tags;
            delete $routeParams.taskId;
            delete $scope.query.taskId;
            $location.url($location.path() + "?tags=bulkSearch:" + $rootScope.bulkSearch.id + "&bulkSearchId=" + $rootScope.bulkSearch.id);
        };

        $scope.showItemReviewTooltip = function(entity) {
            var reviewErrors = _.map(entity.reviewErrors__, function (error) {
                return error.split(':')[3];
            });

            var reviewWarnings =_.map(entity.reviewWarnings__, function (warning) {
                return warning.split(':')[3];
            });

            var reviewRemarks =_.map(entity.reviewRemarks__, function (remark) {
                return remark.split(':')[3];
            });

            var errorsAndWarnings = renderTooltipItems(reviewErrors, 'error')
                .concat(renderTooltipItems(reviewWarnings, 'warning'))
                .concat(renderTooltipItems(reviewRemarks, 'remark')).join("");

            // Show only a tooltip if at least one error or one warning is present.
            if (errorsAndWarnings.length > 0) {
                showTooltip(entity.primaryKey__, 'item_review_' + entity.primaryKey__, errorsAndWarnings);
            }
        };

        function checkItemRepublication() {
            return ($rootScope.hasSettingFeature('SHOW_DIRTY_PUBLICATIONS') || $rootScope.systemSettings.UI_SHOW_ITEM_REPUBLISH_NEEDED_ICON);
        }

        $scope.showItemValidationsTooltip = function (entity) {
            var validationsErrors_ = _.uniq(entity.validation__);
            var validationWarnings_ = _.uniq(entity.validation_warnings__);

            var validationErrors = _.map(validationsErrors_, function (error) {
                return $rootScope.translateValidationLabel(error);
            });

            var validationWarnings = _.map(validationWarnings_, function (warning) {
                return $rootScope.translateValidationLabel(warning);
            });
            var errorsAndWarnings = renderTooltipItems(validationErrors, 'error')
                .concat(renderTooltipItems(validationWarnings, 'warning')).join("");

            showTooltip(entity.primaryKey__, 'item_validations_' + entity.primaryKey__, errorsAndWarnings);
        };

        $scope.checkPublicationsDirty = function (entity) {
            var checkFlag = checkItemRepublication();
            var publishTooltip = checkFlag ? !_.isNil(entity.publications_dirty__) : false;
            return publishTooltip;
        };

        $scope.showPublishValidationsTooltip = function (entity) {
            var publicationErrors = !_.isNil(entity.publications_dirty__);
            var publishedDate = $filter('date')(new Date(entity.publishedAt__), 'medium');

            var publishTooltip = renderTooltipItems([$translate.instant('PUBLICATIONS.ON') + publishedDate], 'success').join("");

            var itemRepublicationFlag = checkItemRepublication();
            if (itemRepublicationFlag) {
                publishTooltip = publicationErrors ? renderTooltipItems([$translate.instant('PUBLICATIONS.DIRTY')], 'error').join("") :
                    renderTooltipItems([$translate.instant('PUBLICATIONS.ON') + publishedDate], 'success').join("");
            }

            showTooltip(entity.primaryKey__, 'publication_' + entity.primaryKey__, publishTooltip);
        };

        $scope.hideTooltip = function (entity) {
            $scope.shouldRemoveTooltip = true;
            $timeout(function() {
                hideTooltip('.hopscotch-bubble');
            }, 600);
        };

        // FIXME: Tooltip functionality should be replaced in favor for 'hint-tooltip-directive.js'
        function showTooltip(itemPrimaryKey, target, dataList) {
            function customRendererFn() {
                var attributeStart = '<div class="item-reviews-tooltip"><ul>';
                var attributeEnd = '</ul></div>' +
                    '<span class="hopscotch-arrow"></span>';
                return attributeStart + dataList + attributeEnd;
            }

            var calloutConfig = {
                id: 'callout_' + itemPrimaryKey.replace(/:/g, "_"), //normalize id
                target: target,
                placement: 'left',
                content: '',
                customRenderer: customRendererFn
            };

            var calloutMgr = hopscotch.getCalloutManager();
            calloutMgr.removeAllCallouts();

            var calloutEle = calloutMgr.createCallout(calloutConfig);
            // Hack: override !important css rules, with inline precedence
            calloutEle.element.style.cssText += 'border: none !important; z-index: 1049;';
            $scope.shouldRemoveTooltip = false;
        }

        function hideTooltip(elementClass) {
            if ($(elementClass + ':hover').length == 0 &&
                  $scope.shouldRemoveTooltip) {
                $(elementClass + ':hover').off('mouseout');
                var calloutMgr = hopscotch.getCalloutManager();
                calloutMgr.removeAllCallouts();
            } else {
                $(elementClass).mouseout(function() {
                    $timeout(function() {
                        hideTooltip(elementClass);
                    }, 600);
                });
            }
        }

        function renderTooltipItems(items, classNames) {
            return _.map(items, function(item) {
                return '<li class="' + classNames + '">' + item + '</li>';
            });
        }

        function init() {

            var inEditItemMode = _.includes($location.path(), EDIT_ITEM_URL);
            var inNewItemMode = _.includes($location.path(), NEW_ITEM_URL);
            var focusSection = _.includes($location.path(), FOCUS_SECTION_URL);
            var focusAttribute = _.includes($location.path(), FOCUS_ATTRIBUTE_URL);
            var section = focusSection ? FOCUS_SECTION_URL + "/" + $routeParams.section : "";
            var attribute = focusAttribute ? FOCUS_ATTRIBUTE_URL + "/" + $routeParams.attribute : "";

            $scope.page = $routeParams.page || 1;
            $scope.searchFinished = false;
            $scope.query = {};

            $scope.plans = $rootScope.communicationPlans;
            if (inEditItemMode === true) {
                var row = {
                    entity: {
                        primaryKey__: ($routeParams.primaryKey === 'new') ? undefined : $routeParams.primaryKey
                    }
                };
                $scope.showEditor(row);
                $location.displayUrl(EDIT_ITEM_URL + $routeParams.primaryKey + section + attribute);
            } else if (inNewItemMode === true) {
                $scope.showEditor();
                $location.displayUrl(NEW_ITEM_URL);
            }

            $q.all([
                loadOrganizations().$promise,
                loadCommunicationChannels().$promise,
            ]).then(function(data) {

                var publicationDestinations = _.map($scope.organizations, function(organization) {
                    return {
                        key: organization.organizationId,
                        name: organization.name,
                        sortKey: "1" + organization.name,
                        isOrganization: true
                    };
                });

                _.forEach($scope.communicationChannels, function(channel) {
                    if (!channel.active) {
                        return;
                    }
                    publicationDestinations.push({
                        key: channel.id,
                        name: channel.name,
                        sortKey: "2" + channel.name,
                        isChannel: true
                    });
                    var plan = $scope.plans[channel.plan];
                    if (plan && plan.supportsSubDestinations && !_.isEmpty(channel.subDestinations)) {
                        for (var subDestinationKey in channel.subDestinations) {
                            var label = channel.subDestinations[subDestinationKey];
                            label = (label && label !== subDestinationKey) ? label + " (" + subDestinationKey + ")" :
                                subDestinationKey;
                            publicationDestinations.push({
                                key: String(channel.id) + ":" + subDestinationKey,
                                name: label,
                                sortKey: "2" + channel.name + label + subDestinationKey,
                                isSubDestination: true
                            });
                        }
                    }
                });

                $scope.publicationDestinations = _.sortBy(publicationDestinations, function(destination) {
                    return destination.sortKey.toLowerCase();
                });
            });

            if ($rootScope.isDataModelLoaded) {
                initDependingOnDatamodel();
            }
            if (!_.isNil($routeParams.taskId)) {
                loadTasks();
            }

        }

        if ($rootScope.isDataModelLoaded) {
            init();
        } else {
            $scope.$on('dataModelLoaded', function() {
                init();
            });
            $scope.$on('templatesLoaded', function() {
                initDependingOnDatamodel();
            });
        }

        $scope.$on('$destroy', function() {
            stopItemChangesQueues();
            stopGridCellEditListeners();
            ChannelService.unregister(ChannelService.ITEM_CHANGED_EVENT);
        });

    });
